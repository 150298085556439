import React from "react";
import { Container, Image, Navbar } from "react-bootstrap";
import logo from "../assets/images/srenalogopng-ar-en-red/srenalogopng-ar-en-red.png";
import appleIcon from "../assets/images/Icon awesome-apple.svg";
import verticalLine from "../assets/images/Line 1.svg";
import androidIcon from "../assets/images/Icon awesome-android.svg";
import Styles from "../styles/Header.module.css";

const Header = () => {
  return (
    <Navbar
      style={{ background: "var(--light-pink-color)", zIndex: "999" }}
      className="d-flex justify-content-center position-fixed w-100"
    >
      <Container>
        <Navbar.Brand href="/">
          <Image
            src={logo}
            className={`${Styles.logo} d-inline-block align-top`}
            alt="Logo"
          />
        </Navbar.Brand>
        <div className="d-flex align-items-center text-capitalize gap-4">
          <h1 className="d-none d-sm-block m-0" style={{ fontSize: "20px" }}>
            get app
          </h1>
          <div className="d-flex align-items-center gap-1 gap-sm-2">
            <a
              href="#"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "var(--dark-black-color)" }}
              className="d-flex align-items-center gap-1 gap-sm-2"
            >
              <Image src={appleIcon} alt="Apple icon" className={Styles.icon} />
              <span className={Styles.span}>on apple store</span>
            </a>
            <Image src={verticalLine} alt="Vertical line" />
            <a
              href="https://play.google.com/store/apps/details?id=com.srena.app"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "var(--dark-black-color)" }}
              className="d-flex align-items-center gap-1 gap-sm-2"
            >
              <Image
                src={androidIcon}
                alt="Android icon"
                className={Styles.icon}
              />
              <span className={Styles.span}>on play store</span>
            </a>
          </div>
        </div>
      </Container>
    </Navbar>
  );
};

export default Header;
