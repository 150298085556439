import React, { useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import car from "../assets/images/car (4)/car (4).png";
import arrowRight from "../assets/images/Group 18929.svg";
import delivery from "../assets/images/food-delivery/food-delivery.png";
import scooter from "../assets/images/kick-scooter (1)/kick-scooter (1).png";
import electricCar from "../assets/images/electric-car (1)/electric-car (1).png";
import managementIcon from "../assets/images/product-management/product-management.png";
import bike from "../assets/images/bike (2)/bike (2).png";
import golfCart from "../assets/images/golf-cart (1)/golf-cart (1).png";
import carWash from "../assets/images/car-wash (1)/car-wash (1).png";
import parkedCar from "../assets/images/parked-car/parked-car.png";
import bookIcon from "../assets/images/Group 18977/Group 18977.png";
import trackIcon from "../assets/images/Group 18976/Group 18976.png";
import manageIcon from "../assets/images/Group 18975/Group 18975.png";
import pinkBackground from "../assets/images/Path 35109/Path 35109.png";
import deliveryMan from "../assets/images/delivery-man.jpg";
import Styles from "../styles/Services.module.css";

const services = [
  {
    id: 1,
    icon: car,
    title: "VIP internal transportation service",
    description:
      "Customer is certain area can order food any time and day with having to wait long time to get your food",
    items: ["Fast", "Offers", "Everytime, Everywhere"],
  },
  {
    id: 2,
    icon: delivery,
    title: "Food Delivery Service",
    description:
      "Customer is certain area can order food any time and day with having to wait long time to get your food",
    items: ["Fast", "Offers", "Everytime, Everywhere"],
  },
  {
    id: 3,
    icon: scooter,
    title: "Mobility Scooter Sharing",
    description:
      "Customer is certain area can order food any time and day with having to wait long time to get your food",
    items: ["Fast", "Offers", "Everytime, Everywhere"],
  },
  {
    id: 4,
    icon: electricCar,
    title: "LSEV low speed electric vehicles sharing services",
    description:
      "Customer is certain area can order food any time and day with having to wait long time to get your food",
    items: ["Fast", "Offers", "Everytime, Everywhere"],
  },
  {
    id: 5,
    icon: managementIcon,
    title: "Delivery Service",
    description:
      "Customer is certain area can order food any time and day with having to wait long time to get your food",
    items: ["Fast", "Offers", "Everytime, Everywhere"],
  },
  {
    id: 6,
    icon: bike,
    title: "E- Scooters & E- Bike services",
    description:
      "Customer is certain area can order food any time and day with having to wait long time to get your food",
    items: ["Fast", "Offers", "Everytime, Everywhere"],
  },
  {
    id: 7,
    icon: golfCart,
    title: "Request Golf Cart",
    description:
      "Customer is certain area can order food any time and day with having to wait long time to get your food",
    items: ["Fast", "Offers", "Everytime, Everywhere"],
  },
  {
    id: 8,
    icon: carWash,
    title: "Request Car wash",
    description:
      "Customer is certain area can order food any time and day with having to wait long time to get your food",
    items: ["Fast", "Offers", "Everytime, Everywhere"],
  },
  {
    id: 9,
    icon: parkedCar,
    title: "Request Valet Parking",
    description:
      "Customer is certain area can order food any time and day with having to wait long time to get your food",
    items: ["Fast", "Offers", "Everytime, Everywhere"],
  },
];

const Services = () => {
  const [showDetails, setShowDetails] = useState(false);
  const [selectedService, setSelectedService] = useState(null);

  const openDetails = (service) => {
    setShowDetails(true);
    setSelectedService(service);
  };

  const closeDetails = () => {
    setShowDetails(false);
  };

  return (
    <section className="services position-relative pb-5 mb-2">
      <Container className={`${Styles.container} px-sm-0 px-md-5`}>
        <Row>
          <Col>
            <h3 className={Styles.h3}>srena services</h3>
          </Col>
        </Row>
        <Row className="boxes position-relative justify-content-between justify-content-sm-center justify-content-lg-between row-gap-5 column-gap-3 px-md-3 px-lg-5">
          {services.map((service) => (
            <Col
              key={service.id}
              xs={6}
              sm={4}
              className={`${Styles.col} box bg-white d-flex justify-content-center align-items-center rounded position-relative z-1`}
            >
              <div className={Styles.absolute}>
                <Image
                  className={`${Styles.icon} position-relative`}
                  src={service.icon}
                  alt={service.icon}
                />
              </div>
              <p className={Styles.p}>{service.title}</p>
              <div
                onClick={() => openDetails(service)}
                className={`${Styles.details} position-absolute bottom-0 end-0 me-2 d-flex align-items-center gap-2`}
              >
                <p className="m-0">Details</p>
                <Image src={arrowRight} alt="Arrow right to see details" />
              </div>
            </Col>
          ))}

          {showDetails && selectedService && (
            <div className={`${Styles.details_box}`}>
              <div className={Styles.col_left}>
                <h4 className={Styles.title}>{selectedService.title}</h4>
                <p className={Styles.description}>
                  {selectedService.description}
                </p>
                <ul className={Styles.ul}>
                  {selectedService.items.map((item) => (
                    <li key={item} className={Styles.li}>
                      {item}
                    </li>
                  ))}
                </ul>
                <a
                  href="#"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`${Styles.button} d-flex justify-content-center align-items-center text-nowrap gap-2 text-white py-3 px-4 text-capitalize`}
                  title="Company profile"
                >
                  Apply Service
                </a>
              </div>
              <Image
                className={Styles.col_right_image}
                src={deliveryMan}
                alt="Delivery man"
              />
            </div>
          )}
        </Row>

        {showDetails && (
          <div className={Styles.overlay} onClick={closeDetails} />
        )}
      </Container>

      <Row className={`${Styles.row2} gap-1 gap-lg-5 justify-content-center`}>
        <Col sx={4} className="">
          <div className="relative-box">
            <Image
              className={Styles.services_icons}
              src={bookIcon}
              alt="Book icon"
            />
          </div>
          <h4 className={Styles.h4}>Book</h4>
          <p className={Styles.p2}>
            Book your transportation needs with just a few clicks
          </p>
        </Col>
        <Col sx={4} className="">
          <div className="relative-box">
            <Image
              className={Styles.services_icons}
              src={trackIcon}
              alt="Track icon"
            />
          </div>
          <h4 className={Styles.h4}>Track</h4>
          <p className={Styles.p2}>
            Track your transportation needs with just a few clicks
          </p>
        </Col>
        <Col sx={4} className="">
          <div className="relative-box">
            <Image
              className={Styles.services_icons}
              src={manageIcon}
              alt="Manage icon"
            />
          </div>
          <h4 className={Styles.h4}>Manage</h4>
          <p className={Styles.p2}>
            manage your transportation needs with just a few clicks
          </p>
        </Col>
      </Row>

      <Image
        className={`${Styles.bg_pink} position-absolute bottom-0 w-100`}
        src={pinkBackground}
        alt="Pink background"
      />
    </section>
  );
};

export default Services;
