import React from "react";
import hiIcon from "../assets/images/hi/hi.png";
import appleIcon from "../assets/images/Icon awesome-apple-white.svg";
import androidIcon from "../assets/images/Icon awesome-android-white.svg";
import car from "../assets/images/WhatsApp Image 2023-02-15 at 11.22.20 AM/WhatsApp Image 2023-02-15 at 11.22.20 AM.jpg";
import buses from "../assets/images/WhatsApp Image 2023-02-15 at 11.22.21 AM/WhatsApp Image 2023-02-15 at 11.22.21 AM.jpg";
import man from "../assets/images/malte-helmhold-W0LtTCq1UjQ-unsplash/malte-helmhold-W0LtTCq1UjQ-unsplash.jpg";
import { Col, Container, Image, Row } from "react-bootstrap";
import Styles from "../styles/Hero.module.css";

const Hero = () => {
  return (
    <Container
      fluid
      className={`${Styles.bg} text-white d-flex flex-column p-4 mb-5`}
    >
      <Row className="justify-content-lg-center p-lg-5 mt-5">
        <Col
          xs={12}
          lg={5}
          className="content d-flex flex-column justify-content-lg-center"
        >
          <div
            className="d-flex align-items-end mb-3"
            style={{ fontFamily: "Hacen Algeria Bd" }}
          >
            <h3 className={Styles.h3}>Say</h3>
            <span className="mb-2">
              <Image className={Styles.hi_icon} src={hiIcon} alt="Hi icon" />
            </span>
            <h3 className={Styles.h3}>to Srena</h3>
          </div>
          <p className={`${Styles.p1} pb-3`}>
            The single app that's revolutionizing internal transportation with
            environmentally-friendly electric vehicles (EVs)!
          </p>
          <p className={`${Styles.p2} text-capitalize mb-2"`}>get app</p>
          <div className="d-flex justify-content-center justify-content-lg-start gap-1 mb-5">
            <a
              href="#"
              target="_blank"
              rel="noopener noreferrer"
              className={`${Styles.button} d-flex justify-content-center align-items-center text-nowrap gap-2 text-white py-3 px-4 text-capitalize`}
              title="On Apple Store"
            >
              <Image src={appleIcon} alt="Apple icon" className={Styles.icon} />
              <span className={Styles.span}>on apple store</span>
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.srena.app"
              target="_blank"
              rel="noopener noreferrer"
              className={`${Styles.button} d-flex justify-content-center align-items-center text-nowrap gap-2 text-white py-3 px-4 text-capitalize`}
              title="On Android Store"
            >
              <Image
                src={androidIcon}
                alt="Android icon"
                className={Styles.icon}
              />
              <span className={Styles.span}>on play store</span>
            </a>
          </div>
        </Col>
        <Col
          sx={12}
          lg={7}
          className="wrapper-images d-flex justify-content-lg-end gap-2"
        >
          <div className="mt-3 d-flex flex-column gap-2 mt-4 mt-sm-5">
            <Image
              className="w-100"
              style={{ borderRadius: "10px" }}
              src={car}
              alt="Car"
            />
            <div style={{ textAlign: "right" }}>
              <Image
                className="w-75"
                style={{ borderRadius: "10px" }}
                src={buses}
                alt="Buses"
              />
            </div>
          </div>
          <div>
            <Image
              className="w-100"
              style={{ borderRadius: "10px" }}
              src={man}
              alt="Someone hold a phone"
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Hero;
